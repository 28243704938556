var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","align":"stretch"}},[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',[_vm._v(" "+_vm._s(_vm.$lang("appAdmin.placementManagement.title"))+" ")]),_c('v-spacer'),(_vm.hasCreateAccess)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"color":"primary","small":"","fab":"","outlined":"","loading":_vm.isSyncWithAdCascadeInProgress,"disabled":_vm.isSyncWithAdCascadeInProgress},on:{"click":_vm.syncWithAdCascade}},on),[_c('v-icon',[_vm._v("mdi-cloud-sync")])],1)]}}],null,false,4056531054)},[_c('span',[_vm._v(_vm._s(_vm.$lang("appAdmin.placementManagement.syncWithAdCascade")))])]),_c('v-btn',{attrs:{"color":"primary","small":"","fab":"","to":{
                name: 'placement_new',
                params: {
                  id: _vm.applicationId,
                },
                query:
                  _vm.placementsFilter.networkNames.length === 1
                    ? { networkNames: _vm.placementsFilter.networkNames }
                    : {},
              }}},[_c('v-icon',[_vm._v("add")])],1)]:_vm._e()],2),(_vm.placementsFilter.previews.length)?_c('v-row',{staticClass:"px-4"},[_c('report-filter-current-state',{attrs:{"previews":_vm.placementsFilter.previews,"is-clickable":false}})],1):_vm._e(),_c('v-data-table',{class:{ 'row-pointer': _vm.hasEditAccess },attrs:{"headers":_vm.headers,"items":_vm.localPlacements,"search":_vm.search,"loading":_vm.isLoading},on:{"click:row":_vm.handleClickByTableRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$lang('components.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-4",attrs:{"color":"primary","small":"","fab":""},on:{"click":function($event){_vm.filterVisible = !_vm.filterVisible}}},on),[_c('v-icon',[_vm._v("mdi-filter-outline")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$lang("filterPanel.show"))+" ")])])],1)]},proxy:true},{key:"item.networkName",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.aliasName || item.networkName)+" ")]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(_vm.hasCreateAccess)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyPlacement(item.id)}}},on),[_c('v-icon',[_vm._v(" content_copy ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang("appAdmin.placementManagement.copyHint")))])]):_vm._e(),(_vm.hasDeleteAccess)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"bottom":"","icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showDeleteDialog(item)}}},on),[_c('v-icon',[_vm._v(" delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$lang("btn.delete")))])]):_vm._e()]}}],null,true)})],1)],1)],1),_c('placements-filter',{attrs:{"filter":_vm.placementsFilter},on:{"apply":_vm.applyFilters},model:{value:(_vm.filterVisible),callback:function ($$v) {_vm.filterVisible=$$v},expression:"filterVisible"}}),_c('confirmation-dialog',{attrs:{"loading":_vm.isPlacementDeleting,"title":_vm.$lang('appAdmin.placementManagement.deleteDialog.title'),"text":_vm.$lang('appAdmin.placementManagement.deleteDialog.body'),"btn-text":_vm.$lang('btn.delete')},on:{"confirm":_vm.deletePlacement},model:{value:(_vm.isDeleteDialogVisible),callback:function ($$v) {_vm.isDeleteDialogVisible=$$v},expression:"isDeleteDialogVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { AdType, FilterPreview, FilterPreviewId } from "@/shared/models";

export interface PlacementsFilter {
  networkNames?: Array<string>;
  adType?: AdType;
  waterfallId?: Array<string>;
  lat?: boolean | null | string;
  deviceType?: string;
}

export default class PlacementsFilterModel {
  constructor(
    public networkNames: Array<string> = [],
    public adType?: AdType,
    public waterfallId: Array<string> = [],
    public lat: boolean | null = null,
    public deviceType?: string
  ) {}

  get previews(): Array<FilterPreview> {
    return [
      ...(this.networkNames.length
        ? [new FilterPreview(FilterPreviewId.NETWORK_NAMES, this.networkNames)]
        : []),
      ...(this.adType
        ? [new FilterPreview(FilterPreviewId.AD_TYPE, this.adType)]
        : []),
      ...(this.waterfallId.length
        ? [new FilterPreview(FilterPreviewId.WATERFALLS_ID, this.waterfallId)]
        : []),
      ...(this.lat !== null
        ? [new FilterPreview(FilterPreviewId.LAT, this.lat)]
        : []),
      ...(this.deviceType
        ? [new FilterPreview(FilterPreviewId.DEVICE_TYPE, this.deviceType)]
        : []),
    ];
  }

  get isEmpty(): boolean {
    return (
      !this.networkNames.length &&
      !this.adType &&
      !this.waterfallId.length &&
      this.lat === null &&
      !this.deviceType
    );
  }

  toRecord(): Record<string, any> {
    return {
      ...(this.networkNames.length ? { networkNames: this.networkNames } : {}),
      ...(this.adType ? { adType: this.adType } : {}),
      ...(this.waterfallId.length ? { waterfallId: this.waterfallId } : {}),
      ...(this.lat !== null ? { lat: this.lat } : {}),
      ...(this.deviceType ? { deviceType: this.deviceType } : {}),
    };
  }

  static of(model: PlacementsFilter) {
    return Object.values(model).length
      ? new PlacementsFilterModel(
          model.networkNames,
          model.adType,
          model.waterfallId,
          model.lat === undefined
            ? null
            : typeof model.lat === "string"
            ? model.lat === "true"
            : model.lat,
          model.deviceType
        )
      : new PlacementsFilterModel();
  }
}








































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import FilterListItem from "@/reports/components/FilterListItem.vue";
import Lat from "@/shared/components/Lat.vue";
import PlacementsFilterModel from "@/settings/models/placements/PlacementsFilterModel";
import {
  AdType,
  Dictionary,
  DictionaryType,
  PLACEMENT_DICTIONARIES,
} from "@/shared/models";

@Component({
  components: {
    FilterListItem,
    Lat,
  },
})
export default class PlacementsFilter extends Vue {
  @Prop() value!: boolean;
  @Prop() filter!: PlacementsFilterModel;

  readonly adTypeItems = Object.values(AdType).map(
    (type: AdType) =>
      new Dictionary(type, this.$lang(`views.perMin.${type.toLowerCase()}`))
  );
  isDictionariesLoading = false;

  get visible(): boolean {
    return this.value;
  }

  set visible(value: boolean) {
    this.$emit("input", value);
  }

  get localFilter(): PlacementsFilterModel {
    return PlacementsFilterModel.of(this.filter);
  }

  get networkIntegrationApplicationItems(): Array<{
    text: string;
    value: string;
  }> {
    return this.$store.state.placementsStore.networkIntegrationApplications.map(
      ({
        networkName,
        aliasName,
      }: {
        networkName: string;
        aliasName: string | null;
      }) => ({
        text: aliasName ?? networkName,
        value: networkName,
      })
    );
  }

  get waterfallIdItems(): Array<string> {
    return this.$store.state.dictionaryStore[
      DictionaryType.WATERFALLS_ID
    ].values.map(({ value }: Dictionary) => value);
  }

  get deviceTypeItems(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[
      DictionaryType.DEVICE_TYPES
    ].values.filter(({ value }: Dictionary) =>
      ["tablet", "phone"].includes(value)
    );
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get isIos(): boolean {
    return (
      this.$store.getters.applicationsById.get(this.applicationId)
        ?.platformType === "ios"
    );
  }

  @Watch("applicationId", { immediate: true })
  async watchApplicationId(value: string) {
    try {
      this.isDictionariesLoading = true;

      await this.$store.dispatch("loadDictionaries", {
        app: value,
        dictionaryTypes: PLACEMENT_DICTIONARIES,
      });
    } finally {
      this.isDictionariesLoading = false;
    }
  }

  applyFilters() {
    this.$emit("apply", this.localFilter);
  }
}





































































































































































import { Component, Watch } from "vue-property-decorator";
import { isEqual } from "lodash";
import { mixins } from "vue-class-component";

import PlacementsFilter from "@/settings/components/placements/PlacementsFilter.vue";
import ConfirmationDialog from "@/shared/components/ConfirmationDialog.vue";
import ReportFilterCurrentState from "@/reports/components/ReportFilterCurrentState.vue";
import PlacementResponseModel from "@/settings/models/placements/PlacementResponseModel";
import PlacementsFilterModel from "@/settings/models/placements/PlacementsFilterModel";
import { NetworkType } from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component({
  components: {
    PlacementsFilter,
    ConfirmationDialog,
    ReportFilterCurrentState,
  },
})
export default class PlacementsView extends mixins(AppSectionAccessMixin) {
  search = "";
  filterVisible = false;
  placementsFilter = new PlacementsFilterModel();
  localPlacements: Array<PlacementResponseModel> = [];
  isDeleteDialogVisible = false;
  deletingPlacementId: number | null = null;

  get placements(): Array<PlacementResponseModel> {
    return this.$store.state.placementsStore.placements;
  }

  get isLoading(): boolean {
    return this.$store.state.placementsStore.isLoading;
  }

  get isPlacementDeleting(): boolean {
    return this.$store.state.placementsStore.isPlacementDeleting;
  }

  get isSyncWithAdCascadeInProgress(): boolean {
    return this.$store.state.placementsStore.isSyncWithAdCascadeInProgress;
  }

  get isIos(): boolean {
    return (
      this.$store.getters.applicationsById.get(this.applicationId)
        ?.platformType === "ios"
    );
  }

  get headers(): Array<Record<string, any>> {
    return [
      {
        text: this.$lang(
          "appAdmin.placementManagement.tableHeaders.placementId"
        ),
        align: "start",
        value: "placementId",
      },
      {
        text: this.$lang(
          "appAdmin.placementManagement.tableHeaders.networkName"
        ),
        align: "start",
        value: "networkName",
      },
      {
        text: this.$lang("appAdmin.placementManagement.tableHeaders.adType"),
        align: "start",
        value: "adType",
      },
      {
        text: this.$lang(
          "appAdmin.placementManagement.tableHeaders.deviceType"
        ),
        align: "start",
        value: "deviceType",
      },
      ...(this.isIos
        ? [
            {
              text: this.$lang("appAdmin.placementManagement.tableHeaders.lat"),
              align: "start",
              value: "lat",
            },
          ]
        : []),
      {
        text: this.$lang(
          "appAdmin.placementManagement.tableHeaders.placementName"
        ),
        align: "start",
        value: "adUnit",
      },
      {
        text: this.$lang("appAdmin.placementManagement.tableHeaders.spotId"),
        align: "start",
        value: "spotId",
      },
      {
        text: this.$lang(
          "appAdmin.placementManagement.tableHeaders.waterfallId"
        ),
        align: "start",
        value: "waterfallId",
      },
      ...(this.hasCreateAccess || this.hasDeleteAccess
        ? [
            {
              text: this.$lang(
                "appAdmin.placementManagement.tableHeaders.actions"
              ),
              value: "actions",
              width: "10%",
              align: "end",
              sortable: false,
            },
          ]
        : []),
    ];
  }

  @Watch("applicationId", { immediate: true })
  async watchApplicationId(applicationId: string) {
    await Promise.all([
      this.$store.dispatch("loadPlacements", applicationId),
      this.$store.dispatch("loadNetworkIntegrationApplications", {
        applicationId,
        params: { networkType: NetworkType.REVENUE },
      }),
    ]);

    this.fillLocalPlacements();
  }

  @Watch("$route.query", { immediate: true })
  watchRouteQuery(value: Record<string, any>) {
    this.placementsFilter = PlacementsFilterModel.of(value);
    this.fillLocalPlacements();
  }

  @Watch("placements", { deep: true })
  watchPlacements() {
    this.fillLocalPlacements();
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("appAdmin.placementManagement.title")
    );
  }

  fillLocalPlacements() {
    this.localPlacements = this.placementsFilter.isEmpty
      ? [...this.placements]
      : this.placements.filter((placement) => {
          const { networkNames, adType, waterfallId, lat, deviceType } =
            this.placementsFilter;

          return (
            (!networkNames.length ||
              networkNames.includes(placement.networkName)) &&
            (!adType || adType === placement.adType) &&
            (!waterfallId.length ||
              waterfallId.includes(placement.waterfallId)) &&
            (lat === null || lat === placement.lat) &&
            (!deviceType || deviceType === placement.deviceType)
          );
        });
  }

  handleClickByTableRow(placement: PlacementResponseModel) {
    if (!this.hasEditAccess) {
      return;
    }

    this.$router.push({
      name: "placement_view",
      params: {
        id: this.applicationId,
        placementId: String(placement.id),
      },
    });
  }

  applyFilters(filter: PlacementsFilterModel) {
    this.filterVisible = false;
    this.placementsFilter = PlacementsFilterModel.of(filter);

    if (isEqual(this.$route.query, this.placementsFilter.toRecord())) {
      return;
    }

    this.$router.push({
      query: this.placementsFilter.toRecord(),
    });
  }

  copyPlacement(id: number) {
    this.$router.push({
      name: "placement_new",
      params: {
        id: this.applicationId,
        placementId: id.toString(),
      },
    });
  }

  syncWithAdCascade() {
    this.$store.dispatch("syncWithAdCascade");
  }

  showDeleteDialog({ id }: PlacementResponseModel) {
    this.deletingPlacementId = id;
    this.isDeleteDialogVisible = true;
  }

  async deletePlacement() {
    await this.$store.dispatch("deletePlacement", this.deletingPlacementId);
    this.isDeleteDialogVisible = false;
  }
}
